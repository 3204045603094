/**
 * Based on a gist from from kflorence - https://gist.github.com/kflorence/3086552
 */
 export const getScrollbarWidth = () => {
  const containerElement = document.createElement('div')
  containerElement.setAttribute('aria-hidden', 'true')
  containerElement.className = 'p-absolute top-0 left-0 overflow-hidden invisible'
  containerElement.style.width = '100px'
  containerElement.style.height = '100px'
  containerElement.innerHTML = '<div class="w-1/1" style="height: 200px"></div>'
  const testElement = containerElement.querySelector('div')
	document.body.appendChild(containerElement);
  const w1 = testElement.offsetWidth
	containerElement.style.overflow = 'scroll';
  const w2 = testElement.offsetWidth
	document.body.removeChild(containerElement);
  return w1 - w2
}

export const getCurrentDocumentScrollbarWidth = () => {
  if ( document.body.clientHeight > window.innerHeight ) {
    return getScrollbarWidth()
  } else {
    return 0
  }
}
