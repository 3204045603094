import {
  assignController, select,
} from '../api/element-manipulation'


assignController('mobileNav', (element) => {
  const mainNav = select(document, '#main-nav');
  const btn = select(element, '.js-mobile-menu-btn');
  const headerMenu = select(element, '.header-menu');

  btn.addEventListener('click', () => onClickHandler());


  // if (headerMenu) {
  //   const navButtons = headerMenu.getElementsByTagName('a');
  //   for (let i = 0; i < navButtons.length; i++) {
  //     const navBtn = navButtons[i];
  //     navBtn.addEventListener('click', () => onNavBtnClickHandler());
  //   }
  // }

  const onClickHandler = (event) => {
    btn.classList.toggle('open');
    mainNav.classList.toggle('open');
    if (headerMenu) headerMenu.classList.toggle('open');
  }

  // const onNavBtnClickHandler = (event) => {
  //   if (btn.classList.contains('open')) {
  //     btn.classList.toggle('open');
  //   }
  //   if (!!headerMenu && headerMenu.classList.contains('open')) {
  //     headerMenu.classList.toggle('open');
  //   }
  // }
})
