const controllers = []
const controllerSpecs = []

export const getControllers = element => controllers[+element.dataset.controllerIndex]

export const unlinkControllers = element => {
  controllers[+element.dataset.controllerIndex] = null
}

export const run = (selector, controllerFunction) => {
  controllerSpecs.push({selector, controllerFunction})
}

export const assignController = (name, controllerFunction) => {
  controllerSpecs.push({name, controllerFunction})
}

export const instantiateControllers = () => {
  controllerSpecs.forEach ( controllerSpec => {
    const elements = Array.from(document.querySelectorAll(
      controllerSpec.name
        ? `[data-hook~="${controllerSpec.name}"]`
        : controllerSpec.selector
    )).filter ( element => {
      return ( typeof element.dataset.controllerIndex === 'undefined' ) || ( typeof getControllers(element)[element.dataset.hook] === 'undefined' )
    })
    if (elements.length > 0) {
      elements.forEach((element) => {
        const name = controllerSpec.name || controllerSpec.selector.replace(/[^a-zA-Z0-9]/g, '')
        if(typeof element.dataset.controllerIndex === 'undefined') {
          const elementControllers = {}
          elementControllers[name] = controllerSpec.controllerFunction(element) || null
          controllers.push(elementControllers)
          element.dataset.controllerIndex = controllers.length - 1
        } else {
          const elementControllers = getControllers(element)
          if ( typeof elementControllers[name] === 'undefined' ) {
            elementControllers[name] = controllerSpec.controllerFunction(element) || null
          }
        }
      })
    }
  })
  controllersAvailableCallbacks.forEach( callback => callback() )
  controllersAvailableCallbacks = []
}

document.addEventListener('DOMContentLoaded', instantiateControllers, {
  once: true,
})

let controllersAvailableCallbacks = []

export const controllersAvailable = callback => {
  controllersAvailableCallbacks.push(callback)
}

export const click = (el, fn) => {
  el.addEventListener('click', fn)
}

export const select = (element, selector) => element.querySelector(selector)

export const selectAll = (element, selector) => {
  return Array.from(element.querySelectorAll(selector))
}

export const selectAlso = (element, selector) => {
  const result = Array.from(element.querySelectorAll(selector))
  if ( element.matches(selector) ) {
    result.unshift(element)
  }
  return result
}

export const others = (item, list) => list.filter(candidate => item !== candidate)

export const listWithAncestors = (element) => {
  const list = []
  let parent = element
  do {
    list.push(parent)
    parent = parent.parentNode
  } while (parent)
  return list
}

export const setClassState = (element, stateClassName) => {
  if (!stateClassName) {
    return
  }
  stateClassName.split(' ').forEach((stateClass) => {
    element.classList.add(stateClass)
  })
}

export const unsetClassState = (element, stateClassName) => {
  if (!stateClassName) {
    return
  }
  stateClassName.split(' ').forEach((stateClass) => {
    element.classList.remove(stateClass)
  })
}

export const escapeMarkup = text => {
  return text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
}

if(!window.thundertech) {
  window.thundertech = {}
}
window.thundertech.instantiateControllers = instantiateControllers
window.thundertech.getControllers = getControllers
window.thundertech.controllersAvailable = controllersAvailable
