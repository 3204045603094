/*

  # Accessible modal

  # Attribute API

  - data-modal-id is required on modals and open buttons.
  - To control the styles of the modal element states, use
    data-class-active and data-class-inactive
  - To close when clicking outside in the DOM tree, use
    data-quick-close="1"
  - To lock the document behind the modal, use either:
    - data-class-lock="1" and add data-class-locked and
      data-class-unlocked on the body element, or
    - use data-position-lock="1" to prevent scrolling by
      reading the current scroll position on open and
      resetting that position on scroll.
  - Script will auto focus an input if it has the data attribute
    data-focus="modal.autoFocus"


  # Accessibility behaviors and instructions

  - id, aria-controls, aria-labelledby, role attributes are
    automatically generated by the script when not present and do
    not need to be added to the markup.
  - Controls aria-expanded and aria-live attributes upon open/close
  - Use visibility or display to make the modal invisible to
    screen readers when closed

  # Other notes

  - You can have as many open and close buttons as you need
  - To make the entire modal background a close button, stretch
    an absolutely-positioned close button contents and then style
    it to have no opacity and sit behind the modal's contents. Use
    cursor: auto to make the pointer not appear as though it is
    a button.

  ## Modal with close button:

  <div class="d-none"
    data-hook="modal"
    data-modal-id="search"
    data-class-active="d-block"
    data-class-inactive="d-none">
    <button type="button" data-hook="modal.closeButton">
    </button>
  </div>

  ## Modal open button:

  <button type="button" data-hook="modal.openButton" data-modal-id="search">
  </button>

  ## Recommended patterns

  modal-media (modalMedia controller) - for stopping iframes, video and audio
  elements from playing when the modal is closed.

  modal-bg-image (modalBgImage controller) - for refreshing background images
  controlled by bgImage when the modal is opened.

*/

import { assignController, click, select, selectAll, setClassState, unsetClassState, getControllers, listWithAncestors } from '../api/element-manipulation'
import { getCurrentDocumentScrollbarWidth } from '../api/scrollbar-width'

assignController('modal', (element) => {
  const docLock = document.body.dataset.classLocked
  const docUnlock = document.body.dataset.classUnlocked
  const autoFocus = select(element, '[data-hook~="modal.autoFocus"]')
  element.setAttribute('role', 'region')
  if ( !element.id ) {
    element.id = `modal-area-${element.dataset.modalId}`
  }

  if ( element.dataset.quickClose === '1' ) {
    document.addEventListener('click', (event) => {
      if((element.dataset.open === '1')
        && (listWithAncestors(event.target).indexOf(element) === -1)) {
        close(event)
      }
    })
  }

  let currentScrollY

  const preventScroll = e => { window.scrollTo(0, currentScrollY) }
  const onOpened = []
  const onClosed = []

  const close = event => {
    if ( element.dataset.positionLock === '1' ) {
      window.removeEventListener('scroll', preventScroll)
    }
    element.dataset.open = '0'
    if (!event.initial) {
      onClosed.forEach ( listener => listener( {sender: controller} ) )
    }
    setClassState(element, element.dataset.classInactive)
    unsetClassState(element, element.dataset.classActive)
    if ( element.dataset.classLock ) {
      setClassState(document.body, docUnlock)
      unsetClassState(document.body, docLock)
      document.documentElement.classList.add('scroll-behavior-auto')
      setTimeout(preventScroll, 10)
      setTimeout(() => {
        document.documentElement.classList.remove('scroll-behavior-auto')
      }, 20)
    }
    element.setAttribute('aria-expanded', 'false')
    element.setAttribute('aria-live', 'off')
  }
  const open = (event) => {
    if ( event && event.stopPropagation && ( element.dataset.open !== '1' ) ) {
      event.stopPropagation()
    }
    currentScrollY = window.scrollY
    if ( element.dataset.positionLock === '1' ) {
      window.addEventListener('scroll', preventScroll)
    }
    element.dataset.open = '1'
    setClassState(element, element.dataset.classActive)
    unsetClassState(element, element.dataset.classInactive)
    if ( element.dataset.classLock ) {
      setClassState(document.body, docLock)
      unsetClassState(document.body, docUnlock)
    }
    if ( element.dataset.scrollAdjust === '1' ) {
      element.setAttribute('aria-expanded', 'true')
      element.setAttribute('aria-live', 'polite')
      element.style.paddingRight = ''
      const computedPadding = +(getComputedStyle(element).paddingRight.replace('px', ''))
      if ( element.scrollHeight <= element.clientHeight ) {
        element.style.paddingRight = `${computedPadding + getCurrentDocumentScrollbarWidth()}px`
      }
    }
    onOpened.forEach ( listener => listener( {sender: controller} ) )
    if( autoFocus ) {
      const temporary = document.createElement('input')
      temporary.setAttribute('type', 'text')
      temporary.style.position = 'absolute'
      temporary.style.opacity = 0
      temporary.style.height = 0
      temporary.style.fontSize = '16px'
      document.body.appendChild(temporary)
      temporary.focus()
      setTimeout(() => {
        autoFocus.focus()
        document.body.removeChild(temporary)
      }, 100)
    }
  }
  document.addEventListener('keydown', event => {
    if ( element.dataset.open !== '1' ) {
      return
    }
    if ( event.key === 'Escape' ) {
      close(event)
    } else if ( event.keyCode === 27 ) {
      close(event)
    }
  })
  if (element.dataset.autoOpen !== '1') {
    close({initial: true})
  } else {
    setTimeout(open, 100)
  }
  const closeButtons = selectAll(element, '[data-hook="modal.closeButton"]')
  const openButtons = selectAll(document, `[data-hook="modal.openButton"][data-modal-id="${element.dataset.modalId}"]`)
  closeButtons.forEach((button, index) => {
    click(button, close)
    if ( !button.id ) {
      button.id = `modal-close-${element.dataset.modalId}-${index}`
    }
    element.setAttribute('aria-controls', element.id)
  })
  openButtons.forEach ( (button, index) => {
    click(button, open)
    if ( !button.id ) {
      button.id = `modal-open-${element.dataset.modalId}-${index}`
    }
    if ( index === 0 ) {
      element.setAttribute('aria-labelledby', button.id)
    }
    element.setAttribute('aria-controls', element.id)
  })
  const controller = {
    element,
    close,
    open,
    closeButtons,
    openButtons,
    onOpened,
    onClosed,
  }
  return controller
})
